// noinspection SpellCheckingInspection

import {$authHost} from "./index";

export const getTelegramUsersStats = async () => {
  const {data} = await $authHost.get('api/telegramusers');
  return data;
}

export const generateTelegramUsersFiles = async () => {
  const {data} = await $authHost.post('api/telegramusers');
  return data;
}

export const getTelegramUserWithPermBans = async () => {
  const {data} = await $authHost.get('api/telegramusers/bans');
  return data;
}

export const setTelegramUserPermBan = async (user, messageId) => {
  const {data} = await $authHost.post(`api/telegramusers/bans`, {user, messageId});
  return data;
}

export const removeTelegramUserPermBan = async (id) => {
  const {data} = await $authHost.delete(`api/telegramusers/bans/${id}`);
  return data;
}

export const getTelegramUserWithIgnoring = async () => {
  const {data} = await $authHost.get('api/telegramusers/ignoring');
  return data;
}

export const setTelegramUserIgnoring = async (user) => {
  const {data} = await $authHost.post(`api/telegramusers/ignoring`, {user});
  return data;
}

export const removeTelegramUserIgnoring = async (id) => {
  const {data} = await $authHost.delete(`api/telegramusers/ignoring/${id}`);
  return data;
}

export const getUserInfo = async (userId) => {
  const {data} = await $authHost.get(`api/telegramusers/info/${userId}`);
  return data;
}

export const getUserInfoByUsername = async (username) => {
  const {data} = await $authHost.get(`api/telegramusers/info/username/${username}`);
  return data;
}

export const getTelegramUsersWithCauses = async () => {
  const {data} = await $authHost.get(`api/telegramusers/ppcauses`);
  return data;
}

export const deleteCausesByTelegramUser = async (telegramUserId) => {
  const {data} = await $authHost.delete(`api/telegramusers/ppcauses/${telegramUserId}`);
  return data;
}

export const getUsersOtherChannels = async () => {
  const {data} = await $authHost.get('api/telegramusers/other-channels/');
  return data;
}

export const getUsersOtherChannelsCount = async () => {
  const {data} = await $authHost.get('api/telegramusers/other-channels/count');
  return data;
}

export const usersOtherChannelsExport = async () => {
  const {data} = await $authHost.get('api/telegramusers/other-channels/export');
  return data;
}

export const deleteUserOtherChannels = async (id, ban = 'false') => {
  const {data} = await $authHost.delete(`api/telegramusers/other-channels/${id}?ban=${ban}`);
  return data;
}

export const updateAdditionalsTelegramUser = async (id, body) => {
  const {data} = await $authHost.patch(`api/telegramusers/${id}/additionals`, body);
  return data;
}

export const getTelegramUsersWithGold = async () => {
  const {data} = await $authHost.get('api/telegramusers/golden');
  return data;
}

export const setTelegramUsersGoldStatus = async (userId) => {
  const {data} = await $authHost.post('api/telegramusers/golden', {userId});
  return data;
}

export const removeTelegramUsersGoldStatus = async (id) => {
  const {data} = await $authHost.delete(`api/telegramusers/golden/${id}`);
  return data;
}

export const getLimitsTelegramUsers = async (monthAndYear) => {
  const {data} = await $authHost.get(`api/telegramusers/limits/${monthAndYear}`);
  return data;
}