import {$authHost} from "./index";

export const getOneServiceSession = async (sessionType) => {
    const {data} = await $authHost.get(`api/service-session/${sessionType}`);
    return data;
}

export const updateOneServiceSession = async (sessionType, formData) => {
    const {data} = await $authHost.patch(`api/service-session/${sessionType}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    });
    return data;
}