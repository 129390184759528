import React, {useEffect, useState} from 'react';
import {Link, useLocation, useNavigate} from "react-router-dom";
import {Button, Container, Tab, Tabs} from "react-bootstrap";
import {addChannel, deleteChannel, getChannels} from "../http/channelAPI";
import ListEntities from "../components/ListEntities";
import TelegramUsers from "../components/TelegramUsers";
import {
	getTelegramUserWithIgnoring,
	getTelegramUserWithPermBans,
	removeTelegramUserIgnoring,
	removeTelegramUserPermBan,
	setTelegramUserIgnoring,
	setTelegramUserPermBan
} from "../http/telegramUsersAPI";
import {addKeysForBlackList, deleteKeysForBlackList, getKeysForBlackList} from "../http/keysForBlackListAPI";
import ServiceVariables from "../components/ServiceVariables";
import FilterSettings from "../components/FilterSettings";
import PpUsersTable from "../components/PPUsersTable";
import ScrollToTopButton from "../components/ScrollToTopButton";
import OtherChannelsAccountBlock from "../components/OtherChannelsAccountBlock";
import GoldenList from "../components/GoldenList";
import LimitsList from "../components/LimitsList";
import queryString from 'query-string';

const AdminPanel = ({setModalSettings}) => {
	const navigate = useNavigate();
	const location = useLocation();
	const [currentEntity, setCurrentEntity] = useState(1);
	const [currentSubEntity, setCurrentSubEntity] = useState(0);
	const [entities, setEntities] = useState([]);
	const [loading, setLoading] = useState(false);

	const getEntity = async () => {
		setLoading(true);
		if (currentEntity === 4) {
			const entities = await getChannels();
			setEntities([...entities]);
			// } else if (currentEntity === 4) {
			// 	const entities = await getIgnoringFilters();
			// 	setEntities([...entities]);
		} else if (currentEntity === 2) {
			const entities = await getTelegramUserWithPermBans();
			setEntities(entities.map(el => {
				const obj = {};
				obj.id = el.id;
				obj.key1 = el.userId;
				if (el.userName)
					obj.key2 = el.userName
				return obj;
			}));
		} else if (currentEntity === 3) {
			const entities = await getTelegramUserWithIgnoring();
			setEntities(entities.map(el => {
				const obj = {};
				obj.id = el.id;
				obj.key1 = el.userId;
				if (el.userName)
					obj.key2 = el.userName
				return obj;
			}));
		} else if (currentEntity === 1) {
			const entities = await getKeysForBlackList();
			setEntities(entities.map(el => {
				return {
					id: el.id,
					key1: el.name
				}
			}))
		}
		setLoading(false);
	}

	const addEntity = async (key1, key2) => {
		if (key1.length === "" || (key2 !== null && key2.length === ""))
			return

		if (+currentEntity === 4)
			await addChannel(key1, key2);
			// else if (+currentEntity === 4)
		// 	await addIgnoringFilter(key1);
		else if (+currentEntity === 2)
			await setTelegramUserPermBan(key1);
		else if (+currentEntity === 3)
			await setTelegramUserIgnoring(key1);
		else if (+currentEntity === 1)
			await addKeysForBlackList(key1);
		await getEntity();
	}

	const deleteEntity = async (id) => {
		if (+currentEntity === 4)
			await deleteChannel(id);
			// else if (+currentEntity === 4)
		// 	await deleteIgnoringFilter(id);
		else if (+currentEntity === 2)
			await removeTelegramUserPermBan(id);
		else if (+currentEntity === 3)
			await removeTelegramUserIgnoring(id);
		else if (+currentEntity === 1)
			await deleteKeysForBlackList(id);
		await getEntity();
	}

	useEffect(() => {
		const queryParams = queryString.parse(location.search);
		if (!queryParams.entity)
			return;

		setCurrentEntity(+queryParams.entity);
	}, []);

	useEffect(() => {
		if (currentEntity === 0)
			setEntities([]);
		if (currentEntity !== 8)
			getEntity();
	}, [currentEntity, currentSubEntity])

	return (
		<Container style={{height: "95%"}} className={"mt-2 d-flex flex-column align-items-center"}>
			{/*<Link to={"/"} className={"mb-2 mt-2"} style={{color: "white", textDecoration: "none"}}>Вернуться к*/}
			{/*	сообщениям</Link>*/}

			<Button
				onClick={() => navigate('/')}
				variant={'secondary'}
				className={'mb-2'}
			>
				Вернуться к сообщениям
			</Button>

			<Tabs
				activeKey={currentEntity}
				onSelect={key => setCurrentEntity(+key)}
				className={"d-flex justify-content-center"}
			>
				<Tab eventKey={1} title={"Black-WORDS"}>
					{
						currentEntity === 1 &&
						<ListEntities entities={entities} type={"blackWords"} loading={loading}
													addEntity={addEntity}
													deleteEntity={deleteEntity} currentEntity={currentEntity}
													currentSubEntity={currentSubEntity}/>
					}
				</Tab>
				<Tab eventKey={2} title={"BlackList"}>
					{currentEntity === 2 &&
						<ListEntities entities={entities} type={"blacklist"} loading={loading} addEntity={addEntity}
													deleteEntity={deleteEntity} currentEntity={currentEntity}
													currentSubEntity={currentSubEntity}/>
					}
				</Tab>
				<Tab eventKey={9} title={"PP Users"}>
					{currentEntity === 9 && <PpUsersTable/>}
				</Tab>
				<Tab eventKey={3} title={"SilverList"}>
					{
						currentEntity === 3 &&
						<ListEntities entities={entities} type={"silverList"} loading={loading} addEntity={addEntity}
													deleteEntity={deleteEntity} currentEntity={currentEntity}
													currentSubEntity={currentSubEntity}/>
					}
				</Tab>
				<Tab eventKey={4} title={"Каналы"}>
					{
						currentEntity === 4 &&
						<ListEntities entities={entities} type={"channel"} loading={loading} addEntity={addEntity}
													deleteEntity={deleteEntity} currentEntity={currentEntity}
													currentSubEntity={currentSubEntity}/>
					}
				</Tab>
				<Tab eventKey={10} title={'GoldenList'}>
					{
						currentEntity === 10 &&
						<GoldenList/>
					}
				</Tab>
				<Tab eventKey={11} title={'Лимиты'}>
					{
						currentEntity === 11 &&
						<LimitsList/>
					}
				</Tab>
				{/*<Tab eventKey={0} title={"Поиск"} style={{width: "100%"}}>*/}
				{/*	<SearchMessages entities={entities} type={"operation"} loading={loading} setLoading={setLoading}*/}
				{/*					setModalSettings={setModalSettings}/>*/}
				{/*</Tab>*/}
				<Tab eventKey={5} title={"Фильтры"}>
					{
						currentEntity === 5 &&
						<FilterSettings
							loading={loading} setLoading={setLoading}
						/>
					}
				</Tab>
				{/*<Tab eventKey={4} title={"Игнорирование"}>*/}
				{/*	<ListEntities entities={entities} type={"ignoring"} loading={loading} addEntity={addEntity}*/}
				{/*				  deleteEntity={deleteEntity} currentEntity={currentEntity}*/}
				{/*				  currentSubEntity={currentSubEntity}/>*/}
				{/*</Tab>*/}
				<Tab title={"Списки"} eventKey={6}>
					{
						currentEntity === 6 &&
						<TelegramUsers/>
					}
				</Tab>
				<Tab title={'Парс ДК'} eventKey={8}>
					{
						currentEntity === 8 &&
						<OtherChannelsAccountBlock/>
					}
				</Tab>
				<Tab title={'Настройки'} eventKey={7}>
					{
						currentEntity === 7 &&
						<ServiceVariables/>
					}
				</Tab>
			</Tabs>
			<ScrollToTopButton/>
		</Container>
	);
};

export default AdminPanel;