import {$authHost} from "./index";

export const getAllServiceVariables = async () => {
    const {data} = await $authHost.get('api/service-variable');
    console.log(data);
    return data;
}

export const getAllServiceVariablesByFilter = async (variables) => {
    const {data} = await $authHost.get(`api/service-variable?filter=${variables}`);
    console.log(data);
    return data;
}

export const updateServiceVariables = async (variables) => {
    const {data} = await $authHost.patch('api/service-variable', {variables});
    return data;
}