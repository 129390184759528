import React from 'react';
import {Button, Modal} from "react-bootstrap";
import {setTelegramUserPermBan} from "../http/telegramUsersAPI";
import {deleteMessage} from "../http/messageAPI";

const ModalController = ({modalSettings, setModalSettings}) => {
	const handleDeleteMessage = async () => {
		modalSettings.setMessages([...modalSettings.messages.filter(el => el.id !== modalSettings.messageId)]);
		await deleteMessage(modalSettings.messageId);
		setModalSettings({show: false, setMessages: null});
	}

	const handleBanMessage = async () => {
		modalSettings.setMessages([...modalSettings.messages.filter(el => el.telegramUserId !== modalSettings.telegramUserId)]);
		await setTelegramUserPermBan(modalSettings.telegramUserId, modalSettings.messageId);
		setModalSettings({show: false, setMessages: null});
	}

	const handleHide = () => {setModalSettings({...modalSettings, show: false})}

	return (
		<Modal show={modalSettings.show} className={"modalController"} onHide={handleHide} centered={true}>
			<Modal.Footer>
				<Button variant="danger" onClick={modalSettings.type === "delete" ? handleDeleteMessage : handleBanMessage}>
					{modalSettings.type === "delete" ? "Удалить" : "Забанить"}
				</Button>
				<Button variant="primary" onClick={handleHide}>
					Отмена
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default ModalController;