import React, {useState} from 'react';
import {Button, CloseButton, Form, InputGroup, ListGroup, Nav, Spinner} from "react-bootstrap";

const ListEntities = ({entities, type, loading, addEntity, deleteEntity}) => {
	const [data, setData] = useState({key1: "", key2: ""})

	return (
		<div className={"d-flex justify-content-center"}>
			{
				loading ?
					<Spinner variant={"light"} animation={"border"} className={"mt-3"}/> :
					<ListGroup className={"mt-3"}>
						<InputGroup className={"mt-3 mb-3"}>
							<Form.Control
								value={data.key1}
								onChange={el => setData({...data, key1: el.target.value})}
								style={{background: "#002e4e", color: "white"}}
								placeholder="Введите значение"
							/>
							{type !== "place" && !["ignoring", "blacklist", "silverList", "blackWords"].includes(type) &&
								<Form.Control
									value={data.key2}
									onChange={el => setData({...data, key2: el.target.value})}
									style={{background: "#002e4e", color: "white"}}
									placeholder="Введите значение"
								/>}
							<Button variant="secondary"
									onClick={() => {
										addEntity(data.key1.trim(), data.key2 !== "" ? data.key2.trim() : null);
										setData({key1: '', key2: ''});
									}}>
								Отправить
							</Button>
						</InputGroup>
						{type === 'blackWords' && <><span className={'text-white'}><b>Всего: {entities.length}</b></span><br/></>}
						{entities.map((entity, index) =>
							type === "channel" ?
								<ListGroup.Item key={JSON.stringify(entity) + index}
												className={"d-flex justify-content-between align-items-center"}
												style={{background: "#002e4e", color: "white"}}>
									<div className={"d-flex"}>
										<div style={{
											marginRight: "15px",
											height: "50px",
											width: "50px",
											borderRadius: "50%",
											backgroundImage: `url('${process.env.REACT_APP_API_URL}api/static/${entity.imageSrc}')`,
											backgroundSize: "cover",
											flexShrink: 1
										}}>

										</div>
										<div>
											{entity.title}
											<br/>
											Изображение: {entity.imageSrc}
											<br/>
											{entity["channelName"]
												?
												<Nav.Link
													href={`https://t.me/${entity["channelName"]}`}
													target={"_blank"}
													style={{color: "rgb(255,129,91)"}}><b>@{entity["channelName"]}</b></Nav.Link>
												: null
											}
										</div>
									</div>
									<CloseButton variant={"white"} onClick={() => deleteEntity(entity.id)}/>
								</ListGroup.Item> :
								<ListGroup.Item key={JSON.stringify(entity) + index}
												className={"d-flex justify-content-between align-items-center"}
												style={{background: "#002e4e", color: "white"}}>
									{entity.key1} {entity.key2 ? `- ${entity.key2}` : ''}
									<CloseButton variant={"white"} onClick={() => deleteEntity(entity.id)}/>
								</ListGroup.Item>
						)}
					</ListGroup>
			}
		</div>
	);
};

export default ListEntities;