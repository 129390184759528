import React, {useEffect, useState} from 'react';
import {BsFillCaretUpFill} from "react-icons/bs";

const ScrollToTopButton = () =>{
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 100) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        setIsVisible(false);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };


    return (
        <button
            className={`btn btn-primary btn-sm ${isVisible ? 'd-block' : 'd-none'} fixed-bottom`}
            style={{
                width: "50px",
                height: "50px",
                position: 'fixed',
                bottom: '20px',
                left: '80vw',
            }}
            onClick={scrollToTop}
        >
            <BsFillCaretUpFill/>
        </button>
    )
}

export default  ScrollToTopButton;