import React, {useEffect, useState} from 'react';
import {Button, Container, Form, Spinner} from "react-bootstrap";
import {check, login} from "../http/userAPI";

const Login = ({setUser, user}) => {
	const [form, setForm] = useState({login: "", password: ""});
	const [waitRequest, setWaitRequest] = useState(false);
	const [stateMessage, setStateMessage] = useState(null)

	const buttonHandler = async () => {
		setWaitRequest(true);
		try{
			const data = await login(form.login, form.password);
			setUser({login: data.login});
		}
		catch (e) {
			setStateMessage(`Ошибка авторизации: ${e.message}`);
		}
		setWaitRequest(false);
	};

	return (
		<Container className={"d-flex justify-content-center align-items-center col-md-5 vh-100"}>
			{
				waitRequest ?
					<Spinner animation="border" variant="light"/>
					:
					<Form>
						<Form.Group className="mb-3" controlId="formBasicLogin">
							<Form.Label style={{color: "white"}}>Логин</Form.Label>
							<Form.Control style={{background: "#191528", color: "white"}} type="email" placeholder="Логин"
										  required value={form.login}
										  onChange={e => setForm({...form, login: e.target.value})}/>
						</Form.Group>

						<Form.Group className="mb-3" controlId="formBasicPassword">
							<Form.Label style={{color: "white"}}>Пароль</Form.Label>
							<Form.Control style={{background: "#191528", color: "white"}} type="password" placeholder="Пароль"
										  required value={form.password}
										  onChange={e => setForm({...form, password: e.target.value})}/>
						</Form.Group>
						{stateMessage !== null ? <Form.Text>{stateMessage.toString()}</Form.Text> : null}
						<Button variant={"outline-dark mt-3"} style={{background: "#5c162e", color: "white"}} onClick={buttonHandler}>
							Войти
						</Button>
					</Form>
			}
		</Container>

	);
};

export default Login;