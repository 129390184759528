import React, {useEffect, useState} from 'react';
import {Container, Tab, Tabs} from "react-bootstrap";
import ListEntities from "./ListEntities";
import {
    addOperationFilter,
    addPlaceFilter, deleteOperationFilter, deletePlaceFilter,
    getOperationFilters,
    getPlaceFilters
} from "../http/filterAPI";

const FilterSettings = ({loading, setLoading}) => {
    const [currentEntity, setCurrentEntity] = useState(0);
    const [currentSubEntity, setCurrentSubEntity] = useState(0);
    const [entities, setEntities] = useState([]);

    const getEntity = async () => {
        setLoading(true);
        if (currentEntity === 0) {
            const entities = await getOperationFilters();
            setEntities([...(entities.filter(entity => entity.type === +currentSubEntity + 1))]);
        } else if (currentEntity === 1) {
            const entities = await getPlaceFilters();
            setEntities([...(entities.filter(entity => entity.type === +currentSubEntity + 1))]);
        }
        setLoading(false);
    }

    const addEntity = async (key1, key2) => {
        if (key1.length === "" || (key2 !== null && key2.length === ""))
            return

        if (+currentEntity === 0)
            await addOperationFilter(key1, key2, +currentSubEntity + 1);
        else if (+currentEntity === 1)
            await addPlaceFilter(key1, +currentSubEntity + 1);
        await getEntity();
    }

    const deleteEntity = async (id) => {
        if (+currentEntity === 0)
            await deleteOperationFilter(id);
        else if (+currentEntity === 1)
            await deletePlaceFilter(id);
        await getEntity();
    }


    useEffect(() => {
        getEntity()
    }, [currentEntity, currentSubEntity])

    return (
        <Container>
            <Tabs
                activeKey={currentEntity}
                onSelect={key => setCurrentEntity(+key)}
                className={'mt-2 d-flex justify-content-center'}
            >
                <Tab eventKey={0} title={"Операции"}>
                    <Tabs className={"mt-2 d-flex justify-content-center"}
                          activeKey={currentSubEntity}
                          onSelect={key => setCurrentSubEntity(+key)}
                    >
                        <Tab eventKey={0} title={"Куплю шек/продам руб"}>
                            <ListEntities entities={entities} type={"operation"} loading={loading} addEntity={addEntity}
                                          deleteEntity={deleteEntity} currentEntity={currentEntity}
                                          currentSubEntity={currentSubEntity}/>
                        </Tab>
                        <Tab eventKey={1} title={"Продам шек/куплю руб"}>
                            <ListEntities entities={entities} type={"operation"} loading={loading} addEntity={addEntity}
                                          deleteEntity={deleteEntity} currentEntity={currentEntity}
                                          currentSubEntity={currentSubEntity}/>
                        </Tab>
                        <Tab eventKey={2} title={"USDT"}>
                            <ListEntities entities={entities} type={"operation"} loading={loading} addEntity={addEntity}
                                          deleteEntity={deleteEntity} currentEntity={currentEntity}
                                          currentSubEntity={currentSubEntity}/>
                        </Tab>
                    </Tabs>
                </Tab>
                <Tab eventKey={1} title={"Места"}>
                    <Tabs className={"mt-2 d-flex justify-content-center"}
                          activeKey={currentSubEntity}
                          onSelect={key => setCurrentSubEntity(+key)}
                    >
                        <Tab eventKey={0} title={"Батям-Холон-Ришон"}>
                            <ListEntities entities={entities} type={"place"} loading={loading} addEntity={addEntity}
                                          deleteEntity={deleteEntity} currentEntity={currentEntity}
                                          currentSubEntity={currentSubEntity}/>
                        </Tab>
                        <Tab eventKey={1} title={"Тель-Авив"}>
                            <ListEntities entities={entities} type={"place"} loading={loading} addEntity={addEntity}
                                          deleteEntity={deleteEntity} currentEntity={currentEntity}
                                          currentSubEntity={currentSubEntity}/>
                        </Tab>
                    </Tabs>
                </Tab>
            </Tabs>
        </Container>
    );
};

export default FilterSettings;