import {$authHost} from "./index";

export const getChannels = async () => {
	const {data} = await $authHost.get('api/channels');
	return data.channels;
}

export const addChannel = async (channel, src) => {
	return await $authHost.post('api/channels', {channel, imageSrc: src});
}

export const deleteChannel = async (id) => {
	return await $authHost.put('api/channels', {id});
}