import React, {useEffect, useState} from 'react';
import {Button, ButtonGroup, Container} from "react-bootstrap";
import {generateTelegramUsersFiles, getTelegramUsersStats} from "../http/telegramUsersAPI";
import {Link} from "react-router-dom";

const TelegramUsers = () => {
	const [telegramUsers, setTelegramUsers] = useState(null);
	const [filesUrl, setFilesUrl] = useState({});
	const [loadFiles, setLoadFiles] = useState(false);

	const loadFilesHandler = () => {
		setLoadFiles(false);
		generateTelegramUsersFiles()
			.then(res=>{
				setFilesUrl({...res});
			})
			.catch(console.error)
			.finally(()=>{
				setLoadFiles(null);
			})
	};

	useEffect(()=>{
		getTelegramUsersStats()
			.then(res=>{
				setTelegramUsers({...res});
			})
			.catch(console.error)
	},[]);

	return (
		<Container style={{color: "white", textAlign: "center"}}>
			{
				telegramUsers ?
					<Container className={"mt-3"}>
						<h4>Web ID: {telegramUsers.webIds}</h4>
						<h4>Web username: {telegramUsers.webUsernames}</h4>
						<h4>Bot username: {telegramUsers.botUsernames}</h4>
						<h4>Perm bans: {telegramUsers.bans}</h4>
						<h4>Perm bans username: {telegramUsers.bansUsernames}</h4>
						<h4>Silver: {telegramUsers.ignoring}</h4>
						<h4>Surfing Channel: {telegramUsers.surfingChannel}</h4>
					</Container>:
					<h4 className={"mt-3"}>Загрузка данных</h4>
			}
			<Container>
				<Button className={"mt-3"} disabled={loadFiles} onClick={loadFilesHandler}>
					Получить файлы
				</Button>
			</Container>
			{
				!loadFiles && Object.keys(filesUrl)?.length === 0 ? null :
					loadFiles && Object.keys(filesUrl)?.length === 0 ?
						<h4 className={"mt-3"}>Файлы генерируются</h4> :
						<ButtonGroup className={"mt-3"}>
							<Link target={"_blank"} to={`${process.env.REACT_APP_API_URL}${filesUrl.urlWebIds}`}>
								<Button>Web ID</Button>
							</Link>
							<Link target={"_blank"} to={`${process.env.REACT_APP_API_URL}${filesUrl.urlWebUsernames}`}>
								<Button>Web username</Button>
							</Link>
							<Link target={"_blank"} to={`${process.env.REACT_APP_API_URL}${filesUrl.urlBotUsernames}`}>
								<Button>Bot username</Button>
							</Link>
							<Link target={"_blank"} to={`${process.env.REACT_APP_API_URL}${filesUrl.urlBanIds}`}>
								<Button>Bans ID</Button>
							</Link>
							<Link target={"_blank"} to={`${process.env.REACT_APP_API_URL}${filesUrl.urlBanUsernames}`}>
								<Button>Bans username</Button>
							</Link>
							<Link target={"_blank"} to={`${process.env.REACT_APP_API_URL}${filesUrl.urlIgnoringIds}`}>
								<Button>Silver ID</Button>
							</Link>
							<Link target={"_blank"} to={`${process.env.REACT_APP_API_URL}${filesUrl.urlSurfingChannelUsernames}`}>
								<Button>Surfing Channel</Button>
							</Link>
						</ButtonGroup>
			}
		</Container>
	);
};

export default TelegramUsers;