import React, {useEffect, useState} from 'react';
import {Button, Form} from "react-bootstrap";
import {getOneServiceSession, updateOneServiceSession} from "../http/serviceSessionAPI";
import {getUsersOtherChannelsCount, usersOtherChannelsExport} from "../http/telegramUsersAPI";

const AccountSettings = ({sessionType}) => {
  const [sessionInfo, setSessionInfo] = useState({
    filename: '',
    jsonFile: '',
    sessionFile: '',
    proxy: ''
  });
  const [newJSONFile, setNewJSONFile] = useState();
  const [newSessionFile, setNewSessionFile] = useState();
  const [requestStatus, setRequestStatus] = useState(null);

  useEffect(() => {
    getSessionInfo()
      .finally();
  }, []);

  const getSessionInfo = () =>
    getOneServiceSession(sessionType)
      .then(data => {
        setSessionInfo({...data})
        setNewJSONFile(null);
        setNewSessionFile(null);
      })
      .catch(alert)

  const handleFileChange = (e, type) => {
    if (type === "JSON")
      setNewJSONFile(e.target.files[0]);
    else
      setNewSessionFile(e.target.files[0]);
  };

  const saveAccount = (e) => {
    e.preventDefault();

    if (sessionInfo.proxy.split(':').length !== 4)
      return alert('Неверный ввод прокси (ip:port:username:password)');

    const formDataAccount = new FormData();
    formDataAccount.append('proxy', sessionInfo.proxy);
    if (newJSONFile)
      formDataAccount.append('jsonFile', newJSONFile);
    if (newSessionFile)
      formDataAccount.append('sessionFile', newSessionFile);

    updateOneServiceSession(sessionType, formDataAccount)
      .then(() => {
        setRequestStatus('Сохранено')
        getSessionInfo()
          .finally()
      })
      .catch(err => setRequestStatus(`Ошибка: ${err.message}`));
  }

  return (
    <>
      <Form className={'mt-3 mb-3'}>
        {/*<Form.Group>*/}
        {/*    <Form.Label style={{color: 'white'}}>Текущее имя файла</Form.Label>*/}
        {/*    <Form.Control*/}
        {/*        type={'text'}*/}
        {/*        value={sessionInfo.filename}*/}
        {/*        disabled*/}
        {/*    />*/}
        {/*</Form.Group>*/}

        <div style={{color: 'white'}} className={'mb-4 border-bottom'}>
          <h6>Информация об аккаунте</h6>
          {
            sessionType === 'main' &&
            <ul>
              <li>Основной парсинг с чатов ТГ</li>
              <li>Автоматическое подключение к чатам из БД</li>
            </ul>
          }
          {
            sessionType === 'sub' &&
            <ul>
              <li>Получение данных о пользователе по Username</li>
              <li>Данные запрашиваются при установке бана или игноринга, если пользователя нет в БД</li>
            </ul>
          }
          {
            sessionType === 'surfing' &&
            <ul>
              <li>Парсинг с Surfing канала</li>
            </ul>
          }
          {
            sessionType === 'antibot' &&
            <ul>
              <li>Пока не действует</li>
            </ul>
          }
          {
            sessionType === 'otherChannels' &&
            <ul>
              <li>Парсинг пользователей из других каналов</li>
            </ul>
          }
        </div>

        <Form.Group className={'mt-2'}>
          <Form.Label style={{color: 'white'}}>JSON File. Текущий: {sessionInfo.jsonFile}</Form.Label>
          <Form.Control
            type={'file'}
            accept={'.json'}
            onChange={e => handleFileChange(e, 'JSON')}
          />
        </Form.Group>

        <Form.Group className={'mt-2'}>
          <Form.Label style={{color: 'white'}}>Session File. Текущий: {sessionInfo.sessionFile}</Form.Label>
          <Form.Control
            type={'file'}
            accept={'.session'}
            onChange={e => handleFileChange(e, 'Session')}
          />
        </Form.Group>

        <Form.Group className={'mt-2'}>
          <Form.Label style={{color: 'white'}}>Прокси Socks5</Form.Label>
          <Form.Control
            type={'text'}
            value={sessionInfo.proxy}
            onChange={e => setSessionInfo({...sessionInfo, proxy: e.target.value})}
          />
        </Form.Group>

        {!!requestStatus && <span className={'text-white d-block mt-2'}>{requestStatus}</span>}
        <Button
          className={'mt-2'}
          onClick={saveAccount}
        >
          Сохранить
        </Button>
      </Form>
    </>
  );
};

export default AccountSettings;