import {useEffect} from "react";

export const generateStyle = (operation, value) => {
	return {
		height: "100%",
		background: operation===value ? "#7c162e" : "#3c162f",
		color: "#fff"
	}
}

export const useTitle = (title) => {
	useEffect(() => {
		const prevTitle = document.title
		document.title = title
		return () => {
			document.title = prevTitle
		}
	})
}