import {$authHost, $host} from "./index";

export const getPlaceFilters = async () => {
	const {data} = await $authHost.get('api/filters/place')
	return data.filters;
}
export const getOperationFilters = async () => {
	const {data} = await $authHost.get('api/filters/operation');
	return data.filters;
}
export const getIgnoringFilters = async () => {
	const {data} = await $authHost.get('api/filters/ignoring');
	return data.filters;
}

export const deletePlaceFilter = async (id) => {
	return await $authHost.put('api/filters/place', {id: id});
}
export const deleteOperationFilter = async (id) => {
	return await $authHost.put('api/filters/operation', {id: id});
}
export const deleteIgnoringFilter = async (id) => {
	return await $authHost.put('api/filters/ignoring', {id: id});
}

export const addPlaceFilter = async (key1, type) => {
	return await $authHost.post('api/filters/place', {key1, type});
}
export const addOperationFilter = async (key1, key2, type) => {
	return await $authHost.post('api/filters/operation', {key1, key2, type})
}
export const addIgnoringFilter = async (key1) => {
	return await $authHost.post('api/filters/ignoring', {key1})
}