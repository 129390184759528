import {$authHost, $host} from "./index";

export const getMessages = async () => {
	const {data} = await $authHost.get(`api/messages`);
	return data;
};

export const getMessagesByUserId = async (userId, messageId = '') => {
	const {data} = await $authHost.get(`api/messages/user/${userId}/${messageId}`);
	return data;
};

export const getMessagesByQuery = async (query) => {
	const {data} = await $authHost.get(`api/messages/search/${query}`);
	return data;
}

export const getUpdate = async (lastTime) => {
	const {data} = await $authHost.get(`api/messages/update?time=${lastTime}`);
	return data;
}

export const deleteMessage = async (id) => {
	const {data} = await $authHost.post(`api/messages/delete`, {
		id: id
	});
	return data;
}

export const getMessagesInExchangeChats = async (userId) => {
	const {data} = await $authHost.get(`api/messages/exchange/${userId}`);
	return data;
}