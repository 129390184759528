import React from 'react';
import {Container, Nav, Tab, Tabs} from "react-bootstrap";

const FilterBar = ({filters, setFilters}) => {

	return (
		<Container>
			<Tabs className={"d-flex justify-content-center mb-2"} activeKey={filters.operation} onSelect={key=>setFilters({...filters, operation: +key})}>
				<Tab title={"Все"} eventKey={-1}/>
				<Tab title={"куп. ₪/про. ₽"} eventKey={0}/>
				<Tab title={"про. ₪/куп. ₽"} eventKey={1}/>
				<Tab title={"USDT"} eventKey={2}/>
				<Tab title={"ост."} eventKey={3}/>
			</Tabs>
			<Tabs className={"d-flex justify-content-center mb-2"} activeKey={filters.place} onSelect={key=>setFilters({...filters, place: +key})}>
				<Tab title={"Все"} eventKey={-1}/>
				<Tab title={"Бат-Хол-Риш"} eventKey={0}/>
				<Tab title={"ТА"} eventKey={1}/>
				<Tab title={"ост."} eventKey={2}/>
			</Tabs>
			<Tabs className={"d-flex justify-content-center"} activeKey={filters.time} onSelect={key=>setFilters({...filters, time: +key})}>
				<Tab title={"48ч"} eventKey={0}/>
				<Tab title={"24ч"} eventKey={1}/>
				<Tab title={"10ч"} eventKey={2}/>
				<Tab title={"5ч"} eventKey={3}/>
			</Tabs>
			{/*<div className={"d-flex justify-content-center mt-4 mb-4"}>*/}
				{/*<Nav.Link target={"_blank"} href={"https://t.me/oksanyshka92"}>*/}
				{/*	<img src={`${process.env.REACT_APP_API_URL}static/oksana.png`}/>*/}
				{/*</Nav.Link>*/}
			{/*</div>*/}
		</Container>
	);
};

export default FilterBar;