import 'bootstrap/dist/css/bootstrap.min.css';
import React, {useEffect, useState} from "react";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import Login from "./components/Login";
import "./App.css";
import Home from "./pages/Home";
import AdminPanel from "./pages/AdminPanel";
import ModalUsernameController from "./components/ModalController";
import {check} from "./http/userAPI";
import MessagesFound from "./pages/MessagesFound";

function App() {
	const [user, setUser] = useState({login: null, token: null, waitRequest: true});
	const [modalSettings, setModalSettings] = useState({show: false});

	useEffect( () => {
		if (localStorage.getItem("token")) {
			setUser({...user, waitRequest: true})
			check(localStorage.getItem("token"))
				.then(data=>{
					setUser({...user, login: data.login, waitRequest: true})
				})
				.catch((e)=>{
					localStorage.removeItem("token");
					setUser({...user, waitRequest: false})
				})
		}
		else
			setUser({...user, waitRequest: false})
	}, []);

	return (
		<BrowserRouter>
			<ModalUsernameController modalSettings={modalSettings} setModalSettings={setModalSettings}/>
			{
				user.waitRequest && user.login === null ?
					<h1>Ожидайте</h1>
					:
				user.login === null && user.waitRequest === false ?
				<Routes>
					<Route path="/login" element={<Login setUser={setUser} user={user}/>}/>
					<Route
						path="*"
						element={<Navigate to="/login" replace/>}
					/>
				</Routes> :
				<Routes>
					<Route path="/" element={<Home user={user} setModalSettings={setModalSettings}/>}/>
					<Route path="/messages/:prompt/:messageId?" element={<MessagesFound setModalSettings={setModalSettings}/>}/>
					<Route path="/admin" element={<AdminPanel setModalSettings={setModalSettings}/>}/>
					<Route
						path="*"
						element={<Navigate to="/" replace/>}
					/>
				</Routes>
			}
		</BrowserRouter>
	);
}

export default App;
