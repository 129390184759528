import {$authHost} from "./index";

export const getKeysForBlackList = async () => {
    const {data} = await $authHost.get('api/keysforblacklist')
    return data;
}
export const addKeysForBlackList = async (name) => {
    const {data} = await $authHost.post('api/keysforblacklist', {name});
    return data;
}
export const deleteKeysForBlackList = async (id) => {
    const {data} = await $authHost.delete(`api/keysforblacklist/${id}`);
    return data;
}