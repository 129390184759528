import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {Button, Container, Stack} from "react-bootstrap";
import CustomCard from "../components/CustomCard";
import {getMessagesByQuery, getMessagesByUserId, getMessagesInExchangeChats} from "../http/messageAPI";
import {getUserInfo, getUserInfoByUsername} from "../http/telegramUsersAPI";
import {useTitle} from "../utils/utils";
import ScrollToTopButton from "../components/ScrollToTopButton";

const MessagesFound = ({setModalSettings}) => {
	const [messages, setMessages] = useState([]);
	const [user, setUser] = useState({});
	const [title, setTitle] = useState('Search');
	const [typeRequest, setTypeRequest] = useState(null);
	const {prompt, messageId} = useParams();

	useTitle(title);

	const searchMessagesTelegramUsersById = (prompt) => {
		getUserInfo(prompt)
			.then(data => {
				setUser({...data});
				setTitle(data?.userName || data?.userId);
			})
			.catch(console.error);
		getMessagesByUserId(prompt, messageId || '')
			.then((data) => setMessages([...data]))
			.catch(console.error);
	}

	const searchMessagesTelegramUsersByKeywords = (prompt) => {
		getMessagesByQuery(prompt)
			.then(res => {
				setMessages([...res]);
				setTitle(prompt);
			})
			.catch(console.error);
		getUserInfoByUsername(prompt)
			.then(res => {
				setUser(res);
				setTitle(res?.userName || res?.userId);
			})
			.catch(console.error);

		setTypeRequest(prompt.includes("@") ? "user" : "text");
	}

	const searchMessagesInExchangeChats = (userId) => {
		getMessagesInExchangeChats(userId)
			.then(res => {
				setMessages([...res]);
				setTitle(userId)
			})
			.catch(alert);
	}

	useEffect(() => {
		if (prompt === 'exchange' && messageId)
			searchMessagesInExchangeChats(messageId)
		else if (isNaN(+prompt))
			searchMessagesTelegramUsersByKeywords(prompt);
		else
			searchMessagesTelegramUsersById(+prompt);
	}, []);

	const closeDocumentHandler = () => {
		window.close();
	}

	return (
		<Container style={{height: "95%"}} className={"mt-2 d-flex flex-column align-items-center"}>
			<Button className={"mt-2 mb-3 col-md-5 col-12"} style={{height: "75px"}} variant={"secondary"}
							onClick={closeDocumentHandler}>ЗАКРЫТЬ ВКЛАДКУ</Button>
			{user && user?.isBlackList === true && typeRequest === "user" &&
				<h3 style={{color: "white", textAlign: "center"}}>Пользователь в бане</h3>}
			{!user && typeRequest === "user" &&
				<h3 style={{color: "white", textAlign: "center"}}>Пользователь отсутствуют</h3>}
			{((messages.length === 0 && typeRequest === "text") || (typeRequest === "user" && user && messages.length === 0)) &&
				<h3 style={{color: "white", textAlign: "center"}}>Сообщения отсутствуют</h3>}

			<Stack gap={2} style={{overflowY: "scroll"}} className="webkitScroll col-md-5 mx-auto">
				{messages.map((value, index) => <CustomCard key={`card-${index}-${value.id}`}
																										message={value}
																										messages={messages}
																										setMessages={setMessages}
																										setModalSettings={setModalSettings}/>)}
			</Stack>

			<ScrollToTopButton/>
		</Container>
	);
};

export default MessagesFound;